import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { MaxWidthBox } from "./MaxWidthBox";
import { GetStartedButton } from "./GetStartedButton";

export const ContactUs = () => (
  <Box pt={4} pb={0} display="flex" justifyContent="center">
    <MaxWidthBox>
      <Box flex={1} alignContent="center">
        <Typography align="center" variant="h4" color="primary.light">
          We're here to help.
        </Typography>
        <Typography align="center">
          For questions, support or other inquiries,{" "}
          <Link component={RouterLink} to="mailto:support@pitch2table.com">
            contact us
          </Link>{" "}
          and we'll get back to you as quickly as we can.
        </Typography>
      </Box>
      <Box pt={4} pb={4} justifyContent="center" display="flex" flex={1} flexDirection="row">
        <GetStartedButton />
      </Box>
    </MaxWidthBox>
  </Box>
);
