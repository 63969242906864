import * as React from "react";
import { ResponsiveAppBar } from "./ResponsiveAppBar";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MaxWidthBox } from "./MaxWidthBox";
import { Footer } from "./Footer";
import { usePageBySlug } from "./queries";
import { options } from "./contentful-helpers";

/**
 * @param {object} props
 * @param {string} props.slug
 */
const PageContent = ({ slug }) => {
  const { data: entry } = usePageBySlug(slug);

  React.useEffect(() => {
    if (!entry) {
      return;
    }
    if (!entry.fields) {
      document.title = "Pitch2Table - Pitch Support for Designers";
      return;
    }

    document.title = `${entry.fields["pageName"]}`;
  }, [entry]);

  if (!entry) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center" py={32}>
        <CircularProgress />
      </Box>
    );
  }

  if (!entry.fields || !entry.fields["pageContent"]) {
    return (
      <Box flex={1} bgcolor="white" justifyContent="center" display="flex">
        <MaxWidthBox>NOT FOUND</MaxWidthBox>
      </Box>
    );
  }

  const content = entry.fields["pageContent"].fields["body"];

  return (
    <Box flex={1} bgcolor="white" alignItems="center" justifyContent="center" display="flex" minHeight="75vh">
      <MaxWidthBox>
        <Box flex={1} justifyContent="center" display="flex" flexDirection="row">
          <Box maxWidth="60ch">
            <Typography variant="h3" align="center" p={2} color="primary.light">
              {entry.fields["pageName"]}
            </Typography>
            <Box>{documentToReactComponents(content, options)}</Box>
          </Box>
        </Box>
      </MaxWidthBox>
    </Box>
  );
};

export const ContentfulPage = () => {
  const slug = useParams().slug;

  return (
    <Box>
      <ResponsiveAppBar />
      <PageContent slug={`${slug}`} />
      <Footer noContactUs />
    </Box>
  );
};
