import * as React from "react";
import Typography from "@mui/material/Typography";

export const Highlight = ({ children }) => {
  return (
    <Typography component="span" variant="inherit" fontWeight="bold" color="primary.light">
      {children}
    </Typography>
  );
};
