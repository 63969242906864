import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Highlight } from "./Highlight";

export const ResponsiveAppBar = () => {
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Stack direction="row" display="flex" flex="1" justifyContent="space-between" alignItems="center">
            <Box display="flex" flex="1" justifyContent="flex-start" alignItems="center">
              <img src="/icon-192.png" width={48} alt="Pitch2Table logo" />
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  pl: 2,
                  fontWeight: 700,
                  textDecoration: "none",
                  color: "white",
                  textTransform: "uppercase",
                }}
              >
                Pitch<Highlight>2</Highlight>Table
              </Typography>
            </Box>
            <Box p={1} />
            {/* <Button href="/new" color="info" variant="text">
              What's New?
            </Button> */}
            <Box p={1} />
            <Button href="/features" color="primary.contrastText" variant="text">
              Features
            </Button>
            <Box p={1} />
            <Box display={{ xs: "none", md: "block" }}>
              <Button href="/support" color="primary.contrastText" variant="text">
                Support
              </Button>
            </Box>
            <Box p={1} />
            <Button href="https://app.pitch2table.com" variant="outlined" style={{ backgroundColor: "white" }}>
              LOGIN
            </Button>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export const BackToFeaturesBar = () => {
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Stack direction="row" display="flex" flex="1" justifyContent="space-between" alignItems="center">
            <Box display="flex" flex="1" justifyContent="flex-start" alignItems="center">
              <a href="/">
                <Box alignItems="center" flex="1" display="flex">
                  <img src="/icon-192.png" width={48} alt="Pitch2Table logo" />
                </Box>
              </a>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  pl: 2,
                  fontWeight: 700,
                  textDecoration: "none",
                  color: "white",
                }}
              >
                Pitch2Table
              </Typography>
            </Box>
            <Button
              href="/features"
              variant="outlined"
              style={{ backgroundColor: "white" }}
              color="info"
              startIcon={<ArrowBack />}
            >
              BACK TO FEATURES
            </Button>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
