import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MaxWidthBox } from "./MaxWidthBox";
import arrow from "../../assets/p2t-arrow.svg";
import { Highlight } from "./Highlight";
import { useHomepageBySlug } from "./queries";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { seaBuckthorn } from "../../theme";
import { GetStartedButton } from "./GetStartedButton";
import { useTheme } from "@mui/material";
/**
 * @param {object} props
 * @param {import("react").ReactNode} props.children
 * @param {string|undefined} props.left
 * @param {string|undefined} props.right
 * @param {string|undefined} props.top
 * @param {string|undefined} props.rotate
 * @param {string|undefined} props.arrowTop
 * @param {string|undefined} props.arrowLeft
 * @param {string|undefined} props.arrowRight
 * @param {string|undefined} props.arrowRotate
 * @param {string|undefined} props.arrowScale
 */
const Callout = ({ children, left, top, right, rotate, arrowLeft, arrowTop, arrowRight, arrowRotate, arrowScale }) => {
  const theme = useTheme();

  return (
    <Box>
      <Box
        p={1}
        style={{
          left,
          top,
          right,
          position: "absolute",
          rotate,
          backgroundColor: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.dark,
          borderWidth: 1,
          borderStyle: "dashed",
          borderRadius: 8,
        }}
        sx={{ boxShadow: 2 }}
      >
        <Typography
          style={{ color: theme.palette.secondary.contrastText, fontFamily: "aktiv-grotesk", fontSize: "1rem" }}
          maxWidth="33ch"
        >
          {children}
        </Typography>
      </Box>
      <Box
        style={{
          left: arrowLeft,
          right: arrowRight,
          top: arrowTop,
          rotate: arrowRotate,
          scale: arrowScale,
          position: "absolute",
        }}
      >
        <img
          src={arrow}
          style={{
            width: 64,
            height: 64,
          }}
          loading="lazy"
          alt=""
        />
      </Box>
    </Box>
  );
};

/**
 * @param {object} props
 * @param {import("react").ReactNode} props.children
 */
const Callout2 = ({ children }) => (
  <Box p={1}>
    <Box
      p={1}
      style={{
        backgroundColor: `#fff`,
        borderColor: seaBuckthorn,
        borderWidth: 1,
        borderStyle: "dashed",
        borderRadius: 8,
      }}
    >
      <Typography style={{ color: "#f9912e", fontFamily: "aktiv-grotesk", fontSize: "1rem" }} maxWidth="33ch">
        {children}
      </Typography>
    </Box>
  </Box>
);

export const HeroImage = ({ image, callouts = [] }) => (
  <Box style={{ position: "relative" }} pt={4}>
    <Card elevation={10}>
      <CardMedia image={image} component="img" sx={{ objectFit: "contain" }} title="Pitch2Table" />
      <Box
        display={{ xs: "none", md: "block" }}
        style={{
          position: "absolute",
          pointerEvents: "none",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
        }}
      >
        {callouts.map((callout) => (
          <Callout
            key={`ontop-${callout.text}`}
            left={callout.left}
            right={callout.right}
            top={callout.top}
            rotate={callout.rotate}
            arrowLeft={callout.arrowLeft}
            arrowRight={callout.arrowRight}
            arrowTop={callout.arrowTop}
            arrowRotate={callout.arrowRotate}
            arrowScale={callout.arrowScale}
          >
            {callout.text}
          </Callout>
        ))}
      </Box>
      <Box display={{ xs: "block", md: "none" }}>
        {callouts.map((callout) => (
          <Callout2 key={`bottom-${callout.text}`}>{callout.text}</Callout2>
        ))}
      </Box>
    </Card>
  </Box>
);

export const Hero = () => {
  const { data: entry } = useHomepageBySlug("designer-home");

  return (
    <Box pt={4} pb={0} display="flex" justifyContent="center">
      <MaxWidthBox>
        <Box flex={1} alignContent="center">
          {/* <Typography variant="h3" p={2} textAlign="center" fontWeight="bold" style={{ lineHeight: 1.1 }}>
            Take your game from <Highlight>idea</Highlight> to <Highlight>test</Highlight> to{" "}
            <Highlight>pitch</Highlight> to the <Highlight>table</Highlight>.
          </Typography> */}
          <Typography variant="h3" p={2} textAlign="center" fontWeight="bold" style={{ lineHeight: 1.1 }}>
            Take your game from <Highlight>pitch</Highlight> to the <Highlight>table</Highlight>
          </Typography>
          {/* <Typography variant="h3" p={2} textAlign="center" fontWeight="bold" style={{ lineHeight: 1.1 }}>
            Take your game from <Highlight>pitch</Highlight> to the <Highlight>table</Highlight>. And now, from{" "}
            <Highlight>idea</Highlight> to <Highlight>pitch</Highlight>.
          </Typography> */}
          <Box p={4}>
            <Typography fontSize={{ md: "1.6rem", xs: "1.0rem" }} textAlign="center">
              Track and manage your pitches, write your games rules, collaborate with co-designers, securely store your
              contracts, track your royalties, analyse your play tests all with follow up reminders so you can focus on
              making the best game you can.
            </Typography>
          </Box>
          <Box pt={8} pb={8} justifyContent="center" display="flex" flex={1} flexDirection="row">
            <GetStartedButton />
          </Box>
          {entry && entry.fields && (
            <HeroImage
              image={`http://${entry.fields.hero.fields.file.url}`}
              callouts={entry.fields.annotatedHero.fields.callout || []}
            />
          )}
        </Box>
      </MaxWidthBox>
    </Box>
  );
};

/**
 * @param {object} props
 * @param {object[]} props.features
 */
export const SecondaryImages = ({ features }) => {
  return features.map((feature) => (
    <Box key={feature.sys.id} pt={4} pb={0} display="flex" justifyContent="center">
      <MaxWidthBox>
        <Box flex={1} alignContent="center">
          <Typography align="center" variant="h4" color="primary.light">
            {feature.fields.title}
          </Typography>
          <Typography align="center" variant="body1" fontSize={{ md: "1.6rem", xs: "1.0rem" }}>
            {feature.fields.description}
          </Typography>
          <HeroImage
            image={`http://${feature.fields.image.fields.file.url}`}
            callouts={feature.fields.annotations || []}
          />
        </Box>
      </MaxWidthBox>
    </Box>
  ));
};
