import { createTheme } from "@mui/material";

export const carissma = "#ec7f98";
const asparagus = "#7ca166";
export const seaBuckthorn = "#f9912e";
export const background = "#F6F5F5";

export const theme = createTheme({
  typography: {
    fontFamily: "proxima-nova, sans-serif",
    h1: {
      fontFamily: "sofia-pro, sans-serif",
      fontWeight: 800,
    },
    h2: {
      fontFamily: "sofia-pro, sans-serif",
      fontWeight: 800,
    },
    h3: {
      fontFamily: "sofia-pro, sans-serif",
      fontWeight: 800,
    },
    h4: {
      fontFamily: "sofia-pro, sans-serif",
      fontWeight: 800,
    },
    h5: {
      fontFamily: "sofia-pro, sans-serif",
      fontWeight: 800,
    },
    h6: {
      fontFamily: "sofia-pro, sans-serif",
      fontWeight: 800,
    },
    body2: {
      fontFamily: "proxima-nova, sans-serif",
    },
  },
  palette: {
    mode: "light",
    background: { default: "#F6F5F5", paper: "#ffffff" },
    primary: {
      main: "#36618e",
      dark: "#274d73",
      light: "#608bb7",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#f3daff",
      dark: "#6b5778",
      contrastText: "#523f5f",
    },
    success: {
      main: asparagus,
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#ba1a1a",
    },
  },
});
